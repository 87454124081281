import React, { useState } from 'react'
import { CssBaseline } from '@mui/material'
import { PageProps } from 'gatsby'

import { SEO } from '../SEO/SEO'
import { FormContext } from '../../context/FormContext'
import { PaymentTypeContext } from '../../context/PaymentTypeContext'
import { LocaleContext } from '../../context/LocaleContext'

export const Layout: React.FC<Omit<PageProps, 'children'>> = ({
  children,
  location,
}) => {
  const [locale, setLocale] = useState('')
  const [formOpen, setFormOpen] = useState(false)
  const [checked, setChecked] = useState(false)

  return (
    <LocaleContext.Provider value={{ locale, setLocale }}>
      <FormContext.Provider value={{ formOpen, setFormOpen }}>
        <PaymentTypeContext.Provider value={{ checked, setChecked }}>
          <SEO location={location} />
          <CssBaseline />
          {children}
        </PaymentTypeContext.Provider>
      </FormContext.Provider>
    </LocaleContext.Provider>
  )
}

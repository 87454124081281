exports.components = {
  "component---src-templates-404-tsx": () => import("./../../../src/templates/404.tsx" /* webpackChunkName: "component---src-templates-404-tsx" */),
  "component---src-templates-about-tsx": () => import("./../../../src/templates/about.tsx" /* webpackChunkName: "component---src-templates-about-tsx" */),
  "component---src-templates-compatibility-tsx": () => import("./../../../src/templates/compatibility.tsx" /* webpackChunkName: "component---src-templates-compatibility-tsx" */),
  "component---src-templates-customisation-tsx": () => import("./../../../src/templates/customisation.tsx" /* webpackChunkName: "component---src-templates-customisation-tsx" */),
  "component---src-templates-faqs-tsx": () => import("./../../../src/templates/faqs.tsx" /* webpackChunkName: "component---src-templates-faqs-tsx" */),
  "component---src-templates-imprint-tsx": () => import("./../../../src/templates/imprint.tsx" /* webpackChunkName: "component---src-templates-imprint-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-plans-tsx": () => import("./../../../src/templates/plans.tsx" /* webpackChunkName: "component---src-templates-plans-tsx" */),
  "component---src-templates-privacy-tsx": () => import("./../../../src/templates/privacy.tsx" /* webpackChunkName: "component---src-templates-privacy-tsx" */)
}


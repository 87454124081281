import React from 'react'
import { GatsbyBrowser } from 'gatsby'
import { Layout } from './Layout/Layout'
import { ThemeProvider } from '@mui/material'
import { theme } from '../style/theme'

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => (
  <ThemeProvider theme={theme}>
    <Layout {...props}>{element}</Layout>
  </ThemeProvider>
)

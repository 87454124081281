import { Translate } from '@mui/icons-material'
import { createTheme, Theme, experimental_sx as sx } from '@mui/material/styles'
import { visit, visitWithTypeInfo } from 'graphql'

export const theme: Theme = createTheme({
  palette: {
    primary: {
      main: '#0052fe',
    },
    secondary: {
      main: '#ac97ff',
    },
    common: {
      white: '#ffffff',
      lightGrey1: '#FAFAFA',
      lightGrey2: '#F7F7F7',
      lightGrey3: '#E8E8E8',
      lightGrey4: '#d6d6d6',
      midGrey1: '#ADADAD',
      midGrey2: '#676767',
      darkGrey: '#282c34',
      black: '#000000',
      violet: '#9452FF',
      lightViolet1: '#DCC3FF',
      lightViolet2: '#F3EEFD',
      lightBlue1: '#d6e3ff',
      lightBlue2: '#EBF1FF',
      lightGreen: '#DEF2C3',
      lightPink1: '#F1E5F1',
    },
  },

  typography: {
    fontFamily: [
      "'Poppins'",
      // "'Source Code Pro'",
      // "'Roboto'",
      // "'Nanum Pen Script'",
      "'Gloria Hallelujah'",
      'sans-serif',
    ].join(','),
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: { scrollBehavior: 'smooth' },
        a: { textUnderlineOffset: '3px' },
        ['a:visited']: { textUnderlineOffset: '3px' },
        ['a:hover']: { textUnderlineOffset: '3px' },
        ['a:active']: { textUnderlineOffset: '3px' },
      },
    },

    // Name of the component
    MuiAlert: {
      styleOverrides: {
        // Name of the slot
        message: {
          fontFamily: 'Poppins',
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        // Name of the slot
        containedPrimary: {
          // Some CSS
          backgroundColor: '#0052fe',
          fontFamily: 'Poppins',
          textTransform: 'capitalize',
          fontSize: '0.8rem',
          padding: '.8rem 1.2rem',
          height: '2.5rem',
        },

        outlinedPrimary: {
          // Some CSS
          fontFamily: 'Poppins',
          textTransform: 'capitalize',
          fontSize: '0.8rem',
          padding: '0.8rem 1.2rem',
          height: '2.5rem',
        },

        textPrimary: {
          // Some CSS
          fontFamily: 'Poppins',
          textTransform: 'capitalize',
          fontSize: '0.8rem',
          padding: '0.8rem 1.2rem',
          height: '2.5rem',
        },

        textSecondary: {
          // Some CSS
          fontFamily: 'Poppins',
          textTransform: 'capitalize',
          fontSize: '0.8rem',
          padding: '.8rem 1.2rem',
          color: '#676767',
          height: '2.5rem',
        },
      },
    },

    MuiTypography: {
      styleOverrides: {
        // Name of the slot
        body1: {
          fontSize: '0.8rem',
          lineHeight: 1.6,

          [`@media screen and (min-width: 1536px)`]: {
            fontSize: '0.9rem',
          },
        },

        body2: {
          fontSize: '0.9rem',
          lineHeight: 1.6,

          [`@media screen and (min-width: 1536px)`]: {
            fontSize: '1rem',
          },
        },

        h1: {
          fontFamily: 'Poppins',
          fontSize: '3rem',
          fontWeight: 400,
          lineHeight: 1.2,

          [`@media screen and (max-width: 600px)`]: {
            fontSize: '3.5rem',
          },
        },

        h2: {
          fontFamily: 'Poppins',
          fontSize: '2.6rem',
          fontWeight: 400,
          lineHeight: 1.2,

          [`@media screen and (max-width: 600px)`]: {
            fontSize: '2.3rem',
          },
        },

        h4: {
          fontFamily: 'Poppins',
          fontWeight: 400,
        },

        overline: {
          fontFamily: 'Gloria Hallelujah',
          color: '#9452FF',
          fontSize: '.8rem',
        },
      },
    },

    MuiTabs: {
      styleOverrides: {
        indicator: {
          display: 'flex',
          justifyContent: 'center',
          margin: '0 1rem',
          transform: 'translateY(-10px)',
          height: '2px',
          backgroundColor: 'transparent',
        },
      },
    },
  },
})
